import React from "react"

import programmer from '../images/programmer.jpg'
const IndexPage = () => (
    <div className="main-area-wrapper" style={{ backgroundImage: `url(${programmer})`, 
        width: '100%',  height: '100vh', margin: 'auto', 
        overflow: 'hidden', position: 'fixed', color: 'white' }} 
        >
        <div className="main-area center-text" >

            <div className="display-table">
                <div className="display-table-cell" style={{margin: '5px'}}>

                    <h1 className="title"><b>Placeholder</b></h1>
                    <p className="desc font-white">This url is normally used for development and testing of projects for clients of&nbsp;<a style={{color: '#EDDBCC'}} href='https://www.witcraft.io'>Witcraft.io</a></p>
                    
                </div>
            </div>
        </div>
    </div>
)

export default IndexPage
